import React, { Component } from 'react'
import styled from 'styled-components'
import { Box, Title3, Large, colors } from 'js-components'
import { Section, WrapperBackground, WrapperSolid } from '../../components/Wrapper'
import { Helmet } from 'react-helmet'
import { VideoFrame } from '../../components/VideoFrame'
import { PageTitle, PageTitle2 } from '../../components/PageTitle'
import Default from '../../components/Layouts/Default';

import DeviceLineupOutline from '../images/deviceLineupOutline.svg'
import IntegrationsGrid from '../images/integrationsGrid.svg'
import AdminAlert from '../images/adminAlert.svg'
import LiveChat from '../images/liveChat.svg'
import VoipThatWorks from '../animations/voipThatWorks.json'

var lottie

export default class PageNotFoundPage extends Component {
  constructor(props) {
    super(props)
    this.animationIsAttached = false
  }

  componentDidMount() {
    lottie = require('lottie-web')
    this.attachAnimation()
  }

  attachAnimation() {
    if (this.animationContainer !== undefined && !this.animationIsAttached) {
      const animationProperties = {
        container: this.animationContainer,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: VoipThatWorks,
      }

      lottie.loadAnimation(animationProperties)
    }
  }

  render() {
    return (
      <Default>
        <Helmet>
          <title>Truly | Built With IT in Mind</title>
        </Helmet>

        <WrapperBackground
          color={colors.trulyDark}
          background='businessScene2'
        >
          <Section flexDirection="row" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width="1">
              <PageTitle2 as="h2" color={colors.accentFog} bold mb="1.5rem" center>
              Built By IT Engineers, For Humans
              </PageTitle2>
              <PageTitle as="p" color={colors.white} mb="1.5rem" center>
                Battle Tested Deployments In Global Enterprises With Hundreds Of Reps
              </PageTitle>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperBackground
          color={colors.trulyDark}
          background='managedByQ'
          gradientType='light'
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
              <Large as="p" color={colors.trulyDark} my="1.5rem">
                "When I got to the admin page, I was like WOW! This is actually designed really well. I had no questions whatsoever. I didn't even need to look at the documentation for it. It is pretty self-explanatory"
              </Large>
              <VideoFrame background="it" videoLink="https://player.vimeo.com/video/268683007?autoplay=1" />
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid
          color='#222'
        >
          <Section flexDirection={['column', 'row']} py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/3]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              EASY TO SET UP
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
                Phone systems are complicated; it  takes forever to provision users and hardware, and requires a technical person to update IVR workflows.
                <br/><br/>
                With Truly, users onboard themselves in seconds through one our many Oauth integrations (Google Apps, Office365), select their own work number and are automatically assigned to workflows.  The system is so user friendly, that even front-line managers can take ownership of their queues.
                <br/><br/>
                This saves IT days of work each month and allows front line managers to address customer needs more quickly.
              </Large>
            </Box>
            <Box width={[1, 1/2]}>
              <CenteredImg src={IntegrationsGrid} alt="Integrations"/>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperSolid>
          <Section flexDirection={['column', 'row']} my={['1rem', '2rem', '5rem']}>
            <Box width={[1/2, 1/2]} pb="2rem">
              <WidthImg src={AdminAlert} alt="Alert"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem">
              BETTER TECHNOLOGY
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem">
                Most phone solutions  are built on third parties like Twilio or CiscoBroadsoft, giving them limited visibility when issues arise.  This causes lots of finger pointing and multi-day resolution times.
                <br/><br/>
                Truly owns and operates a vertically integrated voice infrastructure, with direct carrier partnerships around the world, to ensure every packet can be traced and every issue diagnosed/shared with you immediately.
                <br/><br/>
                As a result, your issues will get solved same-day with little to no work from you.
              </Large>
            </Box>
          </Section>
        </WrapperSolid>

        <WrapperBackground
          color={colors.trulyDark}
          background='businessScene2'
        >
          <Section flexDirection="column" py={['6rem', '7rem']} background={colors.trulyDark}>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.accentFog} bold mb="1.5rem">
              UNMATCHED SUPPORT
              </Title3>
              <Large as="p" color={colors.white} mb="1.5rem">
                All phone system providers have junior front line support reps with multi-hour/day SLAs on time to response and resolution.
                <br/><br/>
                Our support team are comprised of experiended IT support professionals with years of experience operating VOIP systems.  They respond in under 2 mins via support chat, have a CSAT score of 98 and 
                <br/><br/>
                As a result, you'll see us as an extension of your team, and never have to worry about dealing with VOIP issues again.
              </Large>
            </Box>
            <Box width={[1, 1/2]}>
              <CenteredImg src={LiveChat} alt="Live Chat"/>
            </Box>
          </Section>
        </WrapperBackground>

        <WrapperSolid>
          <Section flexDirection="column" my={['1rem', '2rem', '5rem']}>
            <Box width={[1, 2/3]} pb="2rem">
              <WidthImg src={DeviceLineupOutline} alt="Devices"/>
            </Box>
            <Box width={[1, 1/2]}>
              <Title3 as="h2" color={colors.trulyBlue} bold mb="1.5rem" center>
              WORKS WITH YOUR EXISTING HARDWARE
              </Title3>
              <Large as="p" color={colors.trulyDark} mb="1.5rem" center>
              Compatible with your SIP devices (desk phones, conference phones), computers (Windows, Mac, Linux, Chrome OS), and mobile devices (iOS, Android).
              </Large>
            </Box>
          </Section>
        </WrapperSolid>
      </Default>
    )
  }
}

const CenteredImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`

const WidthImg = styled.img`
  display: block;
  max-width: 100%;
  margin: 0 auto;
`
